import PropTypes from "prop-types"
import React from "react"

const Footer = ({ metaData, en = false }) => (
    <footer className="bg-white border-top">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-12 col-lg-4 mb-4 mb-lg-4">
              <h4 className="text-black mb-2 mb-md-4">{metaData.title}</h4>
              <p className="text-black small">Pioneer Solutions enjoys unparalleled access and relationships with the major steamship lines and cargo airlines.</p>
          </div>
          <div className="col-md-12 col-lg-4 mb-4 mb-md-0">
            <h6 className="text-black pb-2 pb-md-4">{en ? "Address" : "Dirección"}</h6>
            <p className="small">Pioneer Solutions LLC <br /> 19223 Colima Road #747 Rowland Heights, CA. 91748</p>
          </div>
          <div className="col-md-12 col-lg-4">
            <h6 className="text-black pb-2 pb-md-4">Contact</h6>
            <ul className="small">
              <li className="mb-2"><i className="mdi"></i><b>Mobile EEUU:</b><br /> (909) 904 9064</li>
              <li className="mb-2"><i className="mdi"></i><b>Steven Shen:</b><br /> steven@pioneersolutions19.com</li>
              <li className="mb-2"><i className="mdi"></i><b>Abdul Ahmad:</b><br /> abdul@pioneersolutions19.com</li>
              <li className="mb-2"><i className="mdi"></i><b>Jose L. Torres:</b><br /> jose@pioneersolutions19.com</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 d-flex justify-content-md-start justify-content-center text-white small">
              <span className="brand py-3">{new Date().getFullYear()} © {metaData.title} </span>
            </div>
            <div className="col-sm-6 d-flex justify-content-md-end justify-content-center">
              <ul className="nav">
                <li className="nav-item">
                  {/* <a href={metaData.socialMedia.facebook} className="nav-link py-3" target="_blank" rel="noopener noreferrer" title="Facebook"><i className="mdi mdi-facebook text-white"> </i></a> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
)

Footer.propTypes = {
  metaData: PropTypes.object,
}

Footer.defaultProps = {
  metaData: ``,
}

export default Footer
